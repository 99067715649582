import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import type { CreateBookingProps } from '@/views/bookings/booking-create/components/use-create-booking-props';
import { getStationOfCarByDate } from '@/hooks/use-current-station-of-car';
import { bookingLocationToLocationDto } from '@/utils/booking-location-to-location-dto';
import type { Car } from '@/entities/cars/car.entity';
import { useCreatePricing } from '@/queries/use-pricing';

export const useCreatePricingForBookingProcess = (props: CreateBookingProps) => {
  const { data: pricing, mutateAsync: createPricing, isPending: isCreating } = useCreatePricing();

  const create = async (extrasMap: Record<string, string>) => {
    if (!props.selectedOffer.value || !props.pickupLocation.value || !props.dropoffLocation.value)
      return;
    const carStation = getStationOfCarByDate(
      props.selectedOffer.value.car as unknown as Car,
      props.startDate.value,
    );
    const hasDivergentDropoffLocation =
      props.dropoffLocation.value.locationType !== BookingLocationType.STATION ||
      props.dropoffLocation.value.station?.id !== carStation.id;
    return createPricing({
      carId: props.selectedOffer.value?.car.id,
      startDate: props.startDate.value,
      endDate: props.endDate.value,
      bookedExtras: Object.entries(extrasMap).map(([extraId, optionId]) => ({
        vehicleExtraId: extraId,
        vehicleExtraOptionId: optionId,
      })),
      customBookedExtras: [],
      hasDivergentDropoffLocation: hasDivergentDropoffLocation,
      transferDeliveryLocation: bookingLocationToLocationDto(props.pickupLocation.value),
      transferReturnLocation: bookingLocationToLocationDto(props.dropoffLocation.value),
      pickupStationId:
        props.pickupLocation.value.locationType === BookingLocationType.STATION
          ? props.pickupLocation.value.station!.id
          : null,
      returnStationId:
        props.dropoffLocation.value.locationType === BookingLocationType.STATION
          ? props.dropoffLocation.value.station!.id
          : null,
      insuranceCaseId: props.insuranceCase.value?.id,
    });
  };

  return { create, pricing, isCreating };
};
