<template>
  <form>
    <BasicOverview
      :booking="booking"
      :is-saving="isSaving"
      show-status
      show-previous-bookings
      @update-booking="updateBooking"
      @has-changed="(changed) => (hasChanged.basicOverview = changed)"
    />

    <Divider />

    <div class="relative mt-4 grid grid-cols-3 gap-4">
      <RangeAndLocation
        v-model:start-date="formValues.startDate"
        v-model:end-date="formValues.endDate"
        v-model:car="formValues.car"
        v-model:price-calculation="formValues.priceCalculation"
        :is-saving="isSaving"
        :booking="booking"
        :disabled="disablePriceChangingModules('rangeAndLocation')"
        :recalculating-price="recalculatingPrice"
        @extend-time-period-of-pricing="extendTimePeriodOfPricingWithAlert"
        @update-booking="updateBooking"
        @has-changed="(changed) => (hasChanged.rangeAndLocation = changed)"
      />

      <RangeInfos
        :booking="booking"
        :pricing="formValues.priceCalculation"
        :is-saving="isSaving || recalculatingPrice"
        :immutable="!$can('update', subject('Booking', booking), 'customInclusiveKm')"
        @recalculate-price="recalculatePrice"
        @update-booking="updateBooking"
        @has-changed="(changed) => (hasChanged.rangeInfos = changed)"
      />
    </div>

    <Divider />

    <Location
      :booking="booking"
      :is-saving="isSaving"
      :dropoff-disabled="!$can('update', subject('Booking', booking), 'dropoffLocation')"
      :pickup-disabled="!$can('update', subject('Booking', booking), 'pickupLocation')"
      @update-booking="updateBooking"
      @update-has-divergent-dropoff-location="hasDivergentDropoffLocation = $event"
      @has-changed="(changed) => (hasChanged.location = changed)"
    />

    <Divider />

    <Transfers
      :booking="booking"
      :disabled="!$can('update', subject('Booking', booking), 'relatedBookings')"
    />

    <Divider />

    <div class="space-y-4">
      <div class="grid grid-cols-3 gap-4">
        <div class="col-span-2">
          <Vehicle
            v-model:car="formValues.car"
            v-model:price-calculation="formValues.priceCalculation"
            class="col-span-2"
            :is-saving="isSaving"
            :booking="booking"
            :disabled="
              disablePriceChangingModules('vehicle') ||
              !$can('update', subject('Booking', booking), 'car')
            "
            :recalculating-price="recalculatingPrice"
            @recalculate-price="recalculatePrice"
            @update-booking="updateBooking"
            @has-changed="(changed) => (hasChanged.vehicle = changed)"
          />
        </div>

        <CustomTotalBasePrice
          v-model:price-calculation="formValues.priceCalculation"
          class="item self-end"
          :booking-id="booking.id"
          :car="booking.car"
          :start-date="formValues.startDate"
          :end-date="formValues.endDate"
          :has-divergent-dropoff-location="hasDivergentDropoffLocation"
          :recalculating-price="recalculatingPrice"
          :is-saving="isSaving"
          :disabled="!$can('customizePrice', subject('Booking', booking))"
          :insurance-case-id="booking.insuranceCase?.id"
          @update-booking="updateBooking"
        />
      </div>

      <UpgradedFrom v-if="initiallyBookedCar" :car="initiallyBookedCar" />
    </div>

    <Divider />

    <Extras
      v-model:extras-confirmed="extrasConfirmed"
      v-model:price-calculation="formValues.priceCalculation"
      :is-saving="isSaving"
      :available-extras="availableExtras"
      :booking="booking"
      :disabled="disablePriceChangingModules('extras')"
      :recalculating-price="recalculatingPrice"
      @recalculate-price="recalculatePrice"
      @update-booking="updateBooking"
      @has-changed="(changed) => (hasChanged.extras = changed)"
    />

    <Divider />

    <CustomExtras
      v-model:price-calculation="formValues.priceCalculation"
      :booking-id="booking.id"
      :recalculating-price="recalculatingPrice"
      :is-saving="isSaving"
      :disabled="
        disablePriceChangingModules('customExtras') ||
        !$can('update', subject('Booking', booking), 'customBookedExtras')
      "
      :booking="booking"
      @recalculate-price="recalculatePrice"
      @update-booking="updateBooking"
      @has-changed="(changed) => (hasChanged.customExtras = changed)"
    />

    <Divider />

    <OtherConditions
      :pricing="formValues.priceCalculation"
      :is-saving="isSaving || recalculatingPrice"
      :booking-id="booking.id"
      @recalculate-price="recalculatePrice"
      @update-booking="updateBooking"
      @has-changed="(changed) => (hasChanged.otherConditions = changed)"
    />

    <template v-if="$can('view', subject('Booking', booking), 'agent')">
      <Divider />
      <Agent :booking="booking" :is-saving="isSaving" @update-booking="updateBooking" />
    </template>

    <template v-if="$can('view', subject('Booking', booking), 'customer')">
      <Divider />
      <Customer
        :booking="booking"
        :start-date="formValues.startDate"
        :is-saving="isSaving"
        @update-booking="updateBooking"
      />
    </template>

    <Divider />

    <div v-show="!showAdditionalDrivers">
      <h2>{{ t('additionalDrivers') }}</h2>
      <CVButton class="mt-3" @click.prevent="showAdditionalDrivers = true">{{
        t('showAdditionalDrivers')
      }}</CVButton>
    </div>

    <div v-show="showAdditionalDrivers">
      <AdditionalDrivers
        v-model:additional-drivers="formValues.additionalDrivers"
        v-model:price-calculation="formValues.priceCalculation"
        :booking="booking"
        :extras-have-changed="hasChanged.extras"
        :custom-extras-have-changed="hasChanged.customExtras"
        :disabled="disablePriceChangingModules('additionalDrivers')"
        :recalculating-price="recalculatingPrice"
        :is-saving="isSaving"
        @recalculate-price="recalculatePrice"
        @update-booking="updateBooking"
        @has-changed="(changed) => (hasChanged.additionalDrivers = changed)"
      />
    </div>

    <Divider />

    <Payments :booking="booking" />

    <Divider />

    <BlockedDeposit :booking="booking" />

    <Divider />

    <Invoices :booking="booking" />

    <Divider />

    <DamagesOfBookingList
      :booking="booking"
      :is-saving="isSaving"
      @update-booking="updateBooking"
    />

    <Divider />

    <div v-if="isAtLeastHandedOver">
      <BookingSummary :booking="booking" :is-saving="isSaving" @update-booking="updateBooking" />

      <Divider />
    </div>

    <div class="mt-3 flex items-end justify-end">
      <CancelButton
        v-if="
          $can(
            'update',
            subject('Booking', { ...booking, status: BookingStatus.CANCELED }),
            'status',
          )
        "
        :booking="booking"
        :is-saving="isSaving"
        class="flex-1"
        @update-booking="updateBooking"
      />
      <div class="flex items-end gap-8 justify-self-end">
        <p
          class="text-right text-base font-medium"
          :class="{
            'text-error': openBalance > 0,
            'text-success': openBalance === 0,
          }"
        >
          {{ t('openBalance') }}
          <span v-currency="openBalance" class="block text-lg" />
        </p>
        <p class="text-right text-lg font-medium">
          <Information>
            <template #text>
              <span>{{ t('totalPrice') }}</span>
            </template>
            <template #modal>{{ t('noDamagesIncluded') }}</template>
          </Information>
          <span
            v-currency="booking.priceCalculation.totalPrice"
            data-testid="total-price"
            class="block text-2xl"
          />
        </p>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import Divider from '@/components/Divider.vue';
import AdditionalDrivers from '@/components/booking-forms/AdditionalDrivers.vue';
import BasicOverview from '@/components/booking-forms/BasicOverview.vue';
import CustomExtras from '@/components/booking-forms/CustomExtras.vue';
import CustomTotalBasePrice from '@/components/booking-forms/CustomTotalBasePrice.vue';
import Extras from '@/components/booking-forms/Extras.vue';
import Invoices from '@/components/booking-forms/Invoices.vue';
import Location from '@/components/booking-forms/Location.vue';
import Payments from '@/components/booking-forms/Payments.vue';
import RangeAndLocation from '@/components/booking-forms/RangeAndLocation.vue';
import RangeInfos from '@/components/booking-forms/RangeInfos.vue';
import Transfers from '@/components/booking-forms/Transfers.vue';
import Vehicle from '@/components/booking-forms/Vehicle.vue';
import Customer from '@/components/booking-forms/customer/Customer.vue';
import DamagesOfBookingList from '@/components/booking-forms/damages/DamagesOfBookingList.vue';
import type {
  AdditionalDriversFormValues,
  Booking,
  UpdateBookingDto,
  UpdateBookingQueryDto,
} from '@/entities/bookings/booking.entity';
import type { Car } from '@/entities/cars/car.entity';
import type { Pricing } from '@/entities/pricing.entity';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { useRecalculatePrice } from '@/hooks/use-recalculate-price';
import { useI18n } from 'vue-i18n';
import { useUpdateBooking } from '@/queries/use-bookings';
import { computed, reactive, ref } from 'vue';
import Agent from './components/Agent.vue';
import OtherConditions from '@/components/booking-forms/OtherConditions.vue';
import BookingSummary from '@/components/booking-forms/BookingSummary.vue';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { useExtendTimePeriodOfPricingWithAlert } from '@/hooks/use-extend-time-period-of-pricing-with-alert';
import CancelButton from './components/CancelButton.vue';
import BlockedDeposit from '@/components/booking-forms/BlockedDeposit.vue';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import Information from '@/components/Information.vue';
import UpgradedFrom from './components/UpgradedFrom.vue';
import { subject } from '@casl/ability';

interface FormValues {
  startDate: string;
  endDate: string;
  car: Car;
  priceCalculation: Pricing;
  additionalDrivers: AdditionalDriversFormValues;
  deposit: number;
  pricePerExtraKm: number;
}

const { t } = useI18n();
const props = defineProps<{
  booking: Booking;
  isSaving?: boolean;
}>();

const hasChanged = reactive({
  vehicle: false,
  rangeAndLocation: false,
  extras: false,
  customExtras: false,
  basicOverview: false,
  rangeInfos: false,
  location: false,
  otherConditions: false,
  additionalDrivers: false,
});

const { isPending: isSavingComponent, mutateAsync: updateBookingAsync } = useUpdateBooking();

const isSaving = computed(() => props.isSaving || isSavingComponent.value);
const openBalance = computed(() => Math.abs(Math.min(props.booking.currentBalance, 0)));

const initiallyBookedCar = computed(() =>
  props.booking.car.id !== props.booking.initiallyBookedCar?.id
    ? props.booking.initiallyBookedCar
    : undefined,
);

const formValues = reactive<FormValues>({
  car: props.booking.car,
  startDate: props.booking.startDate,
  endDate: props.booking.endDate,
  priceCalculation: props.booking.priceCalculation,
  additionalDrivers: props.booking.additionalDrivers ?? [],
  deposit: props.booking.priceCalculation.deposit ?? 0,
  pricePerExtraKm: props.booking.priceCalculation.pricePerExtraKm,
});

const availableExtras = ref(props.booking.priceCalculation?.availableExtras ?? []);
const showAdditionalDrivers = ref(false);
const hasDivergentDropoffLocation = ref(
  props.booking.dropoffLocationType !== BookingLocationType.STATION ||
    props.booking.dropoffStation?.id !== props.booking.car.stations[0].station.id,
);

const isAtLeastHandedOver = computed(
  () =>
    props.booking.status === BookingStatus.HANDED_OVER ||
    props.booking.status === BookingStatus.CAR_RETURNED,
);

const { recalculatePrice, recalculatingPrice: requestingPrice } = useRecalculatePrice(
  formValues,
  computed(() => props.booking.priceCalculation),
  computed(() => props.booking.insuranceCase),
  hasDivergentDropoffLocation,
);

const { extendTimePeriodOfPricingWithAlert, extendingTimePeriodOfPricing } =
  useExtendTimePeriodOfPricingWithAlert(
    formValues,
    computed(() => props.booking.priceCalculation),
    computed(() => props.booking.insuranceCase?.id),
  );

const recalculatingPrice = computed(
  () => requestingPrice.value || extendingTimePeriodOfPricing.value,
);

const updateBooking = async (
  query: { id: string; booking: UpdateBookingDto; query?: UpdateBookingQueryDto },
  onFinished?: () => void,
) => {
  await updateBookingAsync(query);
  if (query.booking.carId) {
    availableExtras.value = props.booking.priceCalculation?.availableExtras ?? [];
  }
  onFinished?.();
};

const extrasConfirmed = ref(false);

const disablePriceChangingModules = (
  ignore: 'additionalDrivers' | 'vehicle' | 'customExtras' | 'extras' | 'rangeAndLocation',
) =>
  Object.entries({
    additionalDrivers: hasChanged.additionalDrivers,
    vehicle: hasChanged.vehicle,
    customExtras: hasChanged.customExtras,
    extras: hasChanged.extras,
    rangeAndLocation: hasChanged.rangeAndLocation,
  }).some(([key, value]) => key !== ignore && value);

usePreventLeavingUnsavedForm(() => Object.values(hasChanged).some((changed) => changed));
</script>

<i18n lang="json">
{
  "en": {
    "totalPrice": "Total Price",
    "noOption": "No",
    "additionalDrivers": "Additional Drivers",
    "showAdditionalDrivers": "Show additional Drivers",
    "openBalance": "Open Balance",
    "noDamagesIncluded": "Damages and fines are not included"
  },
  "de": {
    "totalPrice": "Gesamtpreis",
    "noOption": "Kein",
    "additionalDrivers": "Zusatzfahrer",
    "showAdditionalDrivers": "Zusatzfahrer anzeigen",
    "openBalance": "Offener Betrag",
    "noDamagesIncluded": "Schäden und Ordnungswidrikgeiten sind nicht enthalten"
  }
}
</i18n>
