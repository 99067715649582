<template>
  <form @submit.prevent="onSubmit">
    <div v-if="booking.customer">
      <div v-if="!isSwappingCustomer" class="flex justify-between">
        <div class="flex items-center gap-2.5">
          <p>{{ t('customerAccount') }}:</p>
          <p class="rounded-lg bg-primary/20 px-3 py-1">
            {{ booking.customer?.email }}
          </p>
        </div>
        <CVButton
          v-if="$can('update', subject('Booking', booking), 'customer')"
          outline
          @click.prevent="isSwappingCustomer = true"
          >{{ t('swapAccount') }}</CVButton
        >
      </div>
      <div v-else>
        <SwapCustomer
          v-if="booking.customer"
          :schema="customerSchema"
          :is-saving="isSaving"
          @swap-customer="swapCustomer"
          @cancel="isSwappingCustomer = false"
        />
      </div>
      <Divider class="col-span-3" />
    </div>

    <div class="relative">
      <CustomerForm
        ref="customerForm"
        :booking="booking"
        :is-saving="isSaving"
        @has-changed="(changed) => (hasChanged.customerData = changed)"
        @update-booking="updateBooking"
      />

      <div v-if="isSwappingCustomer" class="position absolute inset-0 bg-accent/70"></div>
    </div>

    <Divider class="col-span-3" />

    <AdditionalDrivers
      ref="additionalDrivers"
      v-model:booked-extras-map="formValues.bookedExtrasMap"
      v-model:additional-drivers="formValues.additionalDrivers"
      v-model:price-calculation="formValues.priceCalculation"
      :booking="booking"
      :recalculating-price="recalculatingPrice"
      :is-saving="isSaving"
      validation-is-mandatory
      @has-changed="(changed) => (hasChanged.additionalDrivers = changed)"
      @recalculate-price="recalculatePrice"
      @update-booking="updateBooking"
    />

    <Divider class="col-span-3" />

    <div class="mt-5 flex justify-end">
      <ButtonNext :is-loading="isSaving" :disabled="unsaved" type="submit">
        {{ t('next') }}
      </ButtonNext>
    </div>
  </form>
</template>

<script lang="ts" setup>
import AdditionalDrivers from '@/components/booking-forms/AdditionalDrivers.vue';
import ButtonNext from '@/components/buttons/ButtonNext.vue';
import Divider from '@/components/Divider.vue';
import type {
  AdditionalDriver,
  Booking,
  UpdateBookingDto,
} from '@/entities/bookings/booking.entity';
import type { Car } from '@/entities/cars/car.entity';
import type { Pricing } from '@/entities/pricing.entity';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { useRecalculatePrice } from '@/hooks/use-recalculate-price';
import { useI18n } from 'vue-i18n';
import { convertToBookedExtrasMap } from '@/utils/booked-extras-map';
import { computed, reactive, ref } from 'vue';
import SwapCustomer from '@/components/booking-forms/customer/SwapCustomer.vue';
import { customerEditSchema } from '@/validation/customer.schema';
import { useUpdateBooking } from '@/queries/use-bookings';
import CustomerForm from './CustomerForm.vue';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import { subject } from '@casl/ability';
import type { User } from '@/entities/auth/user.entity';
import { useGenerateSwapCustomerDto } from '@/components/booking-forms/customer/use-generate-swap-customer-dto';

export interface FormValues {
  startDate: string;
  endDate: string;
  bookedExtrasMap: Record<string, string>;
  car: Car;
  priceCalculation: Pricing;
  additionalDrivers: AdditionalDriver[];
}

const props = defineProps<{
  booking: Booking;
}>();

const emit = defineEmits<{
  (e: 'onSubmit'): void;
}>();

const { t } = useI18n();

const customerForm = ref<InstanceType<typeof CustomerForm>>();
const additionalDrivers = ref<InstanceType<typeof AdditionalDrivers>>();

const hasChanged = reactive({
  customerData: false,
  customerDocuments: false,
  additionalDrivers: false,
});

const isSwappingCustomer = ref(false);

const formValues: FormValues = reactive({
  car: props.booking.car,
  inclusiveKm: props.booking.priceCalculation.inclusiveKm,
  startDate: props.booking.startDate,
  endDate: props.booking.endDate,
  priceCalculation: props.booking.priceCalculation,
  bookedExtrasMap: convertToBookedExtrasMap(props.booking.priceCalculation.bookedExtras),
  additionalDrivers: props.booking.additionalDrivers ?? [],
});
const { mutateAsync: updateBookingAsync, isPending: isSaving } = useUpdateBooking();

const updateBooking = async (
  query: { id: string; booking: UpdateBookingDto },
  onFinished?: () => void,
) => {
  await updateBookingAsync(query);
  onFinished?.();
};

const hasDivergentDropoffLocation = computed(
  () =>
    props.booking.dropoffLocationType !== BookingLocationType.STATION ||
    props.booking.dropoffStation?.id !== props.booking.car.stations[0].station.id,
);

const { recalculatePrice, recalculatingPrice } = useRecalculatePrice(
  formValues,
  computed(() => props.booking.priceCalculation),
  computed(() => props.booking.insuranceCase),
  hasDivergentDropoffLocation,
);

const customerSchema = customerEditSchema(
  computed(() => props.booking.car.vehicleType.minAge),
  computed(() => props.booking.startDate),
);

const generateSwapCustomerDto = useGenerateSwapCustomerDto();
const swapCustomer = async (customer: User) => {
  await updateBooking(
    {
      id: props.booking.id,
      booking: await generateSwapCustomerDto(customer),
    },
    () => {
      isSwappingCustomer.value = false;
    },
  );
};

const onSubmit = async () => {
  if (!customerForm.value || !additionalDrivers.value) return;

  const customerDataValidation = await customerForm.value.validate();
  const additionalDriversValidation = await additionalDrivers.value.validate();
  if (!customerDataValidation.valid || !additionalDriversValidation.valid) return;

  emit('onSubmit');
};

const unsaved = computed(() => Object.values(hasChanged).some((changed) => changed));

usePreventLeavingUnsavedForm(() => unsaved.value);
</script>

<i18n lang="json">
{
  "en": {
    "swapAccount": "Swap Account",
    "back": "Back to Booking Overview",
    "customerAccount": "Linked customer account"
  },
  "de": {
    "swapAccount": "Konto austauschen",
    "back": "Zurück zur Buchungsübersicht",
    "customerAccount": "Verknüpftes Kundenkonto"
  }
}
</i18n>
